import App, { Container } from "next/app";
import Router from "next/router";
import { addLocaleData, IntlProvider } from "react-intl";
import csLocaleData from "react-intl/locale-data/cs";
import fetch from "isomorphic-unfetch";
import Cookies from "js-cookie";
import NProgress from "nprogress";

import Layout from "../components/Layout";
import { langFromPath } from "../lib/helpers";
import { BASE_URL } from "../config";
import messages from "../lang/messages.json";

Router.events.on("routeChangeStart", (url) => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

addLocaleData([...csLocaleData]);
const locale = "cs";

const fetchSitemap = async (lang) => {
  const res = await fetch(`${BASE_URL}/sitemap/${lang}`);
  const data = await res.json();

  Cookies.set("lang", lang);
  Cookies.set("sitemap", JSON.stringify(data));

  return data;
};

class AppWrapper extends App {
  static async getInitialProps({ Component, router, ctx }) {
    let pageProps = {};

    const { asPath } = ctx;
    const lang = langFromPath(asPath);
    const msg = messages[lang];
    let sitemapData;
    let savedSitemap;

    const savedLang = Cookies.get("lang") || locale;
    const sitemapStr = Cookies.get("sitemap");

    if (sitemapStr) {
      savedSitemap = JSON.parse(sitemapStr);
    }

    if (savedLang !== lang || !savedSitemap) {
      sitemapData = await fetchSitemap(lang);
    } else {
      sitemapData = savedSitemap;
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    return { sitemapData, pageProps, lang, msg };
  }

  render() {
    const { Component, pageProps, sitemapData, ctx, lang, msg } = this.props;

    return (
      <Container>
        <IntlProvider locale={lang} messages={msg}>
          <Layout sitemap={sitemapData} lang={lang}>
            <Component {...pageProps} />
          </Layout>
        </IntlProvider>
      </Container>
    );
  }
}

export default AppWrapper;
