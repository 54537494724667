import React, { useEffect, useRef } from "react";
import { Link } from "../../routes";
import RetinaImage from "react-retina-image";
import { FormattedMessage } from "react-intl";

import Container from "../Container";
import "./style.scss";

const logo = "/static/app/img_logo_white.png";
const logo2 = "/static/app/img_logo_white@2x.png";
const icn_fcb = "/static/app/icn_facebook.svg";
const icn_insta = "/static/app/icn_insta.svg";
const iki_logo = "/static/app/iki_logo.svg";

const Index = (props) => {
  const { service, page } = props.links;
  const { locale } = props;

  let pages = [];

  if (page && page.length > 10) {
    page.splice(9);
  }

  pages = [...page];

  return (
    <div className="footer">
      <div className="footer__start">
        <Container>
          <div className="row justify-content-between">
            <div className="col-12 col-md-2">
              <RetinaImage
                src={[logo, logo2]}
                className="footer__logo"
                alt="Alfa Dent"
              />{" "}
            </div>
            <div className="col-6 col-md-2">
              <ul>
                <li>
                  <Link href="index" locale={locale}>
                    <a>
                      <FormattedMessage id="menu.home" defaultMessage="Domů" />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="services" locale={locale}>
                    <a>
                      <FormattedMessage
                        id="menu.services"
                        defaultMessage="Služby"
                      />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="pricing" locale={locale}>
                    <a>
                      <FormattedMessage
                        id="menu.pricing"
                        defaultMessage="Ceník"
                      />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="information" locale={locale}>
                    <a>
                      <FormattedMessage
                        id="menu.information"
                        defaultMessage="Užitečné informace"
                      />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="articles" locale={locale}>
                    <a>
                      <FormattedMessage
                        id="menu.articles"
                        defaultMessage="Články"
                      />
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="d-none d-sm-block col-md-2">
              <ul>
                {service &&
                  service.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link
                          key={item.slug}
                          href="serviceDetail"
                          locale={locale}
                          params={{ slug: item.slug }}
                        >
                          <a>{item.name}</a>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="col-6 col-md-2">
              <ul>
                <li>
                  <Link href="ourTeam" locale={locale}>
                    <a>
                      <FormattedMessage
                        id="menu.team"
                        defaultMessage="Náš tým"
                      />
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="about" locale={locale}>
                    <a>
                      <FormattedMessage
                        id="menu.about"
                        defaultMessage="O klinice"
                      />
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-2">
              <ul>
                <li>
                  <Link href="/sitemap.xml" locale={locale}>
                    <a>
                      <FormattedMessage
                        id="menu.sitemap"
                        defaultMessage="Mapa stránek"
                      />
                    </a>
                  </Link>
                </li>
                {pages &&
                  pages.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link
                          key={item.slug}
                          href="pageDetail"
                          locale={locale}
                          params={{ slug: item.slug }}
                        >
                          <a>{item.name}</a>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="col-12 col-lg-2 social">
              <p>
                <FormattedMessage
                  id="menu.followUs"
                  defaultMessage="Sledujte nás"
                />
              </p>
              <p className="footer__social_icons">
                <a href="https://www.facebook.com/alfadentcz/" target="_blank">
                  <img src={icn_fcb} alt="facebook" />
                </a>
                <a
                  href="https://www.instagram.com/alfadent_praha/"
                  target="_blank"
                >
                  <img src={icn_insta} alt="instagram" />
                </a>
              </p>
            </div>
          </div>
        </Container>
      </div>

      <div className="footer__end">
        <Container classname="footer__end__container">
          <span>&copy; 2021 ALFADENT s.r.o.</span>
          <span>
            Crafted by <a href="mailto:me@alexandrdobes.cz">AD</a> &amp;{" "}
            <a href="https://ikiguys.com/" alt="IKIGUYS s.r.o." target="_blank">
              <img className="iki_logo" src={iki_logo} alt="IKIGUYS" />
            </a>
          </span>
        </Container>
      </div>
    </div>
  );
};

export default Index;
