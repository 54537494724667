import React, { Component } from 'react';
import Head from 'next/head';
import { Alert } from 'reactstrap';

import Header from '../Header';
import Footer from '../Footer';
import './style.scss';

class Layout extends Component {
  render() {
    const { sitemap, lang } = this.props;

    return (
      <div className="layout">
        <Head>
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="dc08004c-6a15-44aa-8e19-e2aceca07bf6"
            data-blockingmode="auto"
            type="text/javascript"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />

          <link
            href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i"
            rel="stylesheet"
          />
          <script src="https://www.google.com/recaptcha/api.js" async defer />

          <script
            src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
            integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
            crossOrigin="anonymous"
          />
          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
            integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
            crossOrigin="anonymous"
          />
          <script
            src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
            integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
            crossOrigin="anonymous"
          />
          <script
            id="CookieDeclaration"
            src="https://consent.cookiebot.com/dc08004c-6a15-44aa-8e19-e2aceca07bf6/cd.js"
            type="text/javascript"
            async
          />
        </Head>
        <Header links={sitemap} locale={lang} />
        {/* Uncomment when info message needed again */}
        <Alert color="info">
          {lang === 'en'
            ? 'Dear clients, from 21.12.2024 to 5.1.2025 the clinic will be closed due to a company holiday. However, we will still be available on the phone. Thank you.'
            : 'Vážení klienti, od 21.12.2024 do 5.1.2025 bude klinika uzavřena z důvodu celozávodní dovolené. Nicméně na telefonu Vám budeme stále k dispozici. Děkujeme.'}
        </Alert>
        <div className="layout__content">{this.props.children}</div>
        <Footer links={sitemap} locale={lang} />
      </div>
    );
  }
}

export default Layout;
