import React, { Component } from "react";
import { withRouter } from "next/router";
import { Link, Router } from "../../routes";
import { FormattedMessage } from "react-intl";
import RetinaImage from "react-retina-image";

import Container from "../Container";
import "./style.scss";

const logo = "/static/app/img_logo_color.png";
const logo2 = "/static/app/img_logo_color@2x.png";
const icn_fcb = "/static/app/icn_facebook_dark.svg";

const faq = {
  cs: { name: "Otázky a odpověďi", slug: "otazky-a-odpovedi" },
  en: { name: "FAQ", slug: "faq" },
};

class Header extends Component {
  state = { showMenu: false };
  isTabletOrMobile = false;

  handleNavigation = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  handleRouting = (route = null) => {
    this.handleNavigation();
    if (route !== null) {
      const { locale } = this.props;
      Router.pushRoute(route, {}, locale);
    }
  };

  handleLanguageSwitch = (lang) => {
    const { locale, router } = this.props;
    this.handleNavigation();
    router.push(`/${lang}`);
  };

  componentDidMount() {
    this.isTabletOrMobile = window.innerWidth < 768;
  }

  render() {
    const { locale } = this.props;
    const { service, information } = this.props.links;

    const switchTo = locale === "cs" ? "en" : "cs";

    return (
      <header className="header">
        <Container>
          <nav className="navbar navbar-expand-md navbar-light">
            <Link href={`/`}>
              <a className="navbar-brand">
                <RetinaImage
                  src={[logo, logo2]}
                  className="header__logo"
                  alt="Alfa Dent"
                />
              </a>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              aria-label="Toggle navigation"
              onClick={this.handleNavigation}
            >
              <span className="navbar-toggler-icon" />
            </button>


            <div
              className={`collapse d-md-flex navbar-collapse${this.state.showMenu ? " show" : ""
                }`}
              id="navbarSupportedContent"
            >
              <div className="top-submenu d-flex w-100">
                <span className="nav-link">Volejte i o víkendu <a href="call:+420 774 444 902">+420 774 444 902</a></span>
                <span>
                  <a
                    key={switchTo}
                    onClick={() => this.handleLanguageSwitch(switchTo)}
                    className="nav-link"
                  >
                    {switchTo}
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.facebook.com/alfadentcz/"
                    target="_blank"
                    className="nav-link"
                  >
                    <img src={icn_fcb} className="icnFcb" alt="facebook" />
                  </a>
                </span>
              </div>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                  <Link href="services" locale={locale}>
                    <a
                      className={`nav-link dropdown${this.isTabletOrMobile ? "" : "-toggle"}`}
                      id="servicesDropdown"
                      data-toggle="dropdown"
                      onClick={() => this.handleRouting("services")}
                    >
                      <FormattedMessage
                        id="menu.services"
                        defaultMessage="Služby"
                      />
                    </a>
                  </Link>
                  {!this.isTabletOrMobile &&
                    <div
                      className="dropdown-menu"
                      aria-labelledby="servicesDropdown"
                    >
                      {service &&
                        service.map((item) => {
                          return (
                            <Link
                              key={item.slug}
                              href="serviceDetail"
                              locale={locale}
                              params={{ slug: item.slug }}
                            >
                              <a
                                className="dropdown-item nav-link"
                                onClick={this.handleNavigation}
                              >
                                {item.name}
                              </a>
                            </Link>
                          );
                        })}
                    </div>
                  }
                </li>
                <li className="nav-item">
                  <Link href="pricing" locale={locale}>
                    <a className="nav-link" onClick={this.handleNavigation}>
                      <FormattedMessage
                        id="menu.pricing"
                        defaultMessage="Ceník"
                      />
                    </a>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link href="information" locale={locale}>
                    <a
                      className={`nav-link dropdown${this.isTabletOrMobile ? "" : "-toggle"}`}
                      id="informationDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => this.handleRouting("information")}
                    >
                      <FormattedMessage
                        id="menu.information"
                        defaultMessage="Užitečné informace"
                      />
                    </a>
                  </Link>
                  {!this.isTabletOrMobile &&
                    <div
                      className="dropdown-menu"
                      aria-labelledby="informationDropdown"
                    >
                      <Link
                        key={faq[locale].slug}
                        href="pageDetail"
                        locale={locale}
                        params={{ slug: faq[locale].slug }}
                      >
                        <a
                          className="dropdown-item nav-link"
                          onClick={this.handleNavigation}
                        >
                          {faq[locale].name}
                        </a>
                      </Link>
                      {information &&
                        information.map((item) => {
                          return (
                            <Link
                              key={item.slug}
                              href="informationDetail"
                              locale={locale}
                              params={{ slug: item.slug }}
                            >
                              <a
                                className="dropdown-item nav-link"
                                onClick={this.handleNavigation}
                              >
                                {item.name}
                              </a>
                            </Link>
                          );
                        })}
                    </div>
                  }
                </li>
                {locale === "cs" && (
                  <li className="nav-item">
                    <Link href="articles" locale={locale}>
                      <a className="nav-link" onClick={this.handleNavigation}>
                        <FormattedMessage
                          id="menu.articles"
                          defaultMessage="Články"
                        />
                      </a>
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link href="ourTeam" locale={locale}>
                    <a className="nav-link" onClick={this.handleNavigation}>
                      <FormattedMessage
                        id="menu.ourTeam"
                        defaultMessage="Náš tým"
                      />
                    </a>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    href="pageDetail"
                    locale={locale}
                    params={{
                      slug: locale === "en" ? "ordering-process" : "jak-na-objednani",
                    }}
                  >
                    <a className="nav-link" onClick={this.handleNavigation}>
                      <FormattedMessage
                        id="menu.order"
                        defaultMessage="Jak na objednání"
                      />
                    </a>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link href="contact" locale={locale}>
                    <a className="nav-link" onClick={this.handleNavigation}>
                      <FormattedMessage
                        id="menu.contact"
                        defaultMessage="Kontakty"
                      />
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </Container>
        <div className="mobile-call-button-fixed">
          <a href="tel:+420 251 625 056">
            <FormattedMessage
              id="callButton"
              defaultMessage="Zavolat"
            />
          </a>
        </div>
      </header >
    );
  }
}

export default withRouter(Header);
